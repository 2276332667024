exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-compliance-jsx": () => import("./../../../src/pages/compliance.jsx" /* webpackChunkName: "component---src-pages-compliance-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-csr-jsx": () => import("./../../../src/pages/csr.jsx" /* webpackChunkName: "component---src-pages-csr-jsx" */),
  "component---src-pages-hse-jsx": () => import("./../../../src/pages/hse.jsx" /* webpackChunkName: "component---src-pages-hse-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-marinesecurity-jsx": () => import("./../../../src/pages/marinesecurity.jsx" /* webpackChunkName: "component---src-pages-marinesecurity-jsx" */),
  "component---src-pages-services-jsx": () => import("./../../../src/pages/services.jsx" /* webpackChunkName: "component---src-pages-services-jsx" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

